<template>
	<div>
		<h1>Welcome Postulante</h1>
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
};
</script>
