<template>
	<component :is="template" />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '../components/shared/loading/Modal.vue';
//Layouts
import admin_layout from './admin/layout.vue';
import postulant_layout from './postulant/layout.vue';

export default {
	components: {
		Loading,
		admin_layout,
		postulant_layout,
	},
	data() {
		return {
			template: 'Loading',
		};
	},
	mounted() {
		if (!localStorage.getItem('token')) {
			this.$router.push('/');
		} else {
			this.eventBus();
			this.mtd_checkAuth();
		}
	},
	computed: {
		...mapGetters(['url_api', 'authenticated_user']),
	},
	created() {},
	methods: {
		...mapActions(['get', 'execute_commit']),
		eventBus: function () {
			window.eventBus = this.$bus;
		},
		mtd_checkAuth() {
			this.get({ url: `${this.url_api}/users/check-auth` })
				.then(res => {
					this.template = this.mtd_getLayoutForRole();
					this.execute_commit({ mutation: 'AUTH__USER_SAVE', payload: { user: res.data } });
					this.execute_commit({ mutation: 'requirements_state/IS_EDITABLE__UPDATE', payload: this.authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' });

					localStorage.setItem('permissions', res.data.permissions);
					document.cookie = `permissions=${res.data.permissions};`;
				})
				.catch(() => {
					localStorage.clear();
					this.$router.push('/');
				});
		},
		mtd_getLayoutForRole() {
			const rol = this.authenticated_user.roles[0];
			switch (rol.code) {
				case 'DEVELOPER':
					return 'admin_layout';
				case 'GENERAL_ADMINISTRATOR':
					return 'admin_layout';
				case 'ADMINISTRATOR':
					return 'admin_layout';
				case 'REQUIREMENT_RESPONSABLE':
					return 'admin_layout';
				case 'SIGNING_CONTRACT_RESPONSABLE':
					return 'admin_layout';
				case 'SELECTION_ADMINISTRATOR':
					return 'admin_layout';
				case 'DOCTOR':
					return 'admin_layout';
				case 'POSTULANT':
					return 'admin_layout';
			}
		},
	},
};
</script>
