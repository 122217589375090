<template>
	<div class="main-header">
		<a style="cursor: pointer" class="nav-link nav-link-faded rounded-circle nav-icon" @click="mtd_sidebar" data-toggle="sidebar"><i class="material-icons">menu</i></a>
		<div class="ml-auto">
			<span class="separator"></span>
			<div id="userbox" class="userbox">
				<a href="#" data-toggle="dropdown">
					<figure class="profile-picture">
						<img :src="`${this.url_web}/${authenticated_user.photo}`" class="rounded-circle" />
					</figure>
					<div class="profile-info">
						<span class="name">{{ `${authenticated_user.names} ${authenticated_user.father_last_name} ${authenticated_user.mother_last_name}` }}</span>
						<span class="role">{{ authenticated_user.roles[0].name }}</span>
					</div>

					<i class="fa custom-caret"></i>
				</a>

				<div class="dropdown-menu">
					<ul class="list-unstyled mb-2">
						<li class="divider"></li>
						<li>
							<a role="menuitem" tabindex="-1" @click="mtd_salir"><i class="ri-shut-down-line"></i> Salir</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapGetters, mapActions } from 'vuex';
export default {
	computed: {
		...mapGetters(['authenticated_user', 'url_api', 'url_web']),
	},
	methods: {
		...mapActions(['execute_commit', 'get']),
		mtd_salir: function () {
			this.get({ url: `${this.url_api}/users/logout` })
				.then(() => {
					document.cookie = 'permissions=';
					localStorage.clear();
					this.$router.push('/');
				})
				.catch(() => {});
		},
		mtd_sidebar: function () {
			this.execute_commit({ mutation: 'AUTH__STATE_TOGGLE', payload: {} });
		},
	},
};
</script>
