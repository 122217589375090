const menu = [
	{
		heading: 'MENU',
	},
	{
		name: 'Escritorio',
		path: '/home',
		icon: 'ri-home-8-line',
		permission: 'home-index',
	},
	{
		name: 'Requerimientos',
		icon: 'fas fa-book-reader',
		permissions: ['home-index'],
		submenu: [
			{
				name: 'Historial',
				path: '/selection_administrator/requirements/index',
				permission: 'home-index',
			},
		],
	},
	{
		name: 'Reportes',
		icon: 'ri-bar-chart-2-line',
		permissions: ['maintenances-index'],
		submenu: [
			{
				name: 'Requerimientos',
				path: '/reports/requirements',
				permission: 'maintenances-index',
			},
		],
	},
	{
		name: 'Mantenimientos',
		icon: 'ri-file-copy-2-line',
		permissions: ['maintenances-index'],
		submenu: [
			{
				name: 'Empresas',
				path: '/selection_administrator/enterprises',
				permission: 'maintenances-index',
			},
			{
				name: 'Sedes',
				path: '/selection_administrator/campus',
				permission: 'maintenances-index',
			},
			{
				name: 'Puestos de trabajo',
				path: '/selection_administrator/job_positions',
				permission: 'maintenances-index',
			},
			{
				name: 'Bonificaciones',
				path: '/selection_administrator/benefit_types',
				permission: 'maintenances-index',
			},
			{
				name: 'Fuentes de postulación',
				path: '/selection_administrator/sources',
				permission: 'maintenances-index',
			},
			{
				name: 'Usuarios',
				path: '/selection_administrator/users',
				permission: 'maintenances-index',
			},
		],
	},
	/** CAMBIAR CONTRASEÑA */
	{
		name: 'Cambiar contraseña',
		path: '/change-password',
		icon: 'ri-lock-line',
		permission: 'home-index',
	},
];

export default menu;
