const menu = [
	{
		heading: 'MENU',
	},
	{
		name: 'Escritorio',
		path: '/home',
		icon: 'ri-home-8-line',
		permission: 'home-index',
	},
	// {
	// 	name: 'Req-coordinador',
	// 	icon: 'ri-file-copy-2-line',
	// 	permissions: ['home-index'],
	// 	submenu: [
	// 		{
	// 			name: 'Nuevo',
	// 			path: '/coordinator/requirements/create',
	// 			permission: 'home-index',
	// 		},
	// 		{
	// 			name: 'Historial',
	// 			path: '/coordinator/requirements/index',
	// 			permission: 'home-index',
	// 		},
	// 	],
	// },
	{
		name: 'Req-gerente',
		icon: 'ri-file-copy-2-line',
		permissions: ['home-index'],
		submenu: [
			{
				name: 'Requerimientos gerente',
				path: '/requirements-gerente/',
				permission: 'home-index',
			},
		],
	},
	{
		name: 'Estado inicio',
		icon: 'ri-file-copy-2-line',
		permissions: ['home-index'],
		submenu: [
			{
				name: 'state-start',
				path: '/state-start',
				permission: 'home-index',
			},
		],
	},
	{
		name: 'Estado en proceso',
		icon: 'ri-file-copy-2-line',
		permissions: ['home-index'],
		submenu: [
			{
				name: 'state-start',
				path: '/state-process',
				permission: 'home-index',
			},
		],
	},
	/** CAMBIAR CONTRASEÑA */
	{
		name: 'Cambiar contraseña',
		path: '/change-password',
		icon: 'ri-lock-line',
		permission: 'home-index',
	},
];

export default menu;
