//Menus
import developer_menu from './developer';
import general_administrator from './general_administrator';
import administrator from './administrator';
import requirement_responsable from './requirement_responsable';
import signing_contract_responsable from './signing_contract_responsable';
import selection_administrator from './selection_administrator';
import doctor from './doctor';
import postulant from './postulant';

const menu = {
	DEVELOPER: developer_menu,
	GENERAL_ADMINISTRATOR: general_administrator,
	ADMINISTRATOR: administrator,
	REQUIREMENT_RESPONSABLE: requirement_responsable,
	SIGNING_CONTRACT_RESPONSABLE: signing_contract_responsable,
	SELECTION_ADMINISTRATOR: selection_administrator,
	DOCTOR: doctor,
	POSTULANT: postulant,
};

export default menu;
