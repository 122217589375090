<template>
	<div class="sidebar">
		<!-- Sidebar header -->
		<div class="sidebar-header">
			<a href="/home" class="logo-auth"> <img src="/images/logo.png" alt="Logo" id="main-logo" /> KM GROUP </a>
			<a @click="mtd_sidebar" class="nav-link nav-icon rounded-circle ml-auto" data-toggle="sidebar" type="button"><i class="fas fa-times"></i></a>
		</div>
		<!-- /Sidebar header -->

		<!-- Sidebar body -->
		<simplebar class="sidebar-body" id="sidebar-menu">
			<ul class="metismenu list-unstyled">
				<li class="nav-label">MENU</li>
				<template v-for="(item, key) in menu">
					<!-- Single Menu -->
					<router-link :key="key" tag="li" :to="item.path" active-class="item-active" v-if="!item.heading && !item.submenu && can.includes(item.permission)">
						<a @click="toggleItemCollapse('')" class="nav-link has-icon" :title="item.name">
							<i :class="item.icon"></i>
							<span class="ml-1">{{ item.name }}</span>
						</a>
					</router-link>

					<!-- Menu With Subitems -->
					<li class="nav-item" v-if="!item.heading && item.submenu && mtd_showSection(item.permissions)" :key="key">
						<a
							class="nav-link has-icon treeview-toggle has-arrow"
							:class="[collapse[item.name] ? 'show' : '', routeActiveClass(getSubRoutes(item))]"
							:title="item.name"
							@click="toggleItemCollapse(item.name)">
							<i :class="item.icon"></i>
							<span class="ml-1">{{ item.name }}</span>
						</a>

						<ul class="nav sub-menu">
							<template v-for="(sitem, key) in item.submenu">
								<router-link tag="li" class="nav-item" :key="key" :to="sitem.path" active-class="nav-active" v-if="can.includes(sitem.permission)">
									<a class="nav-link" :title="sitem.name">{{ sitem.name }}</a>
								</router-link>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</simplebar>
		<!-- /Sidebar body -->
	</div>
	<!-- /Sidebar -->
</template>
<script>
/** Vuex */
import { mapGetters, mapActions } from 'vuex';
/** Components */
import simplebar from 'simplebar-vue';
//Menu
import rol_menu from '@/menus/index.js';
export default {
	components: {
		simplebar,
	},
	data() {
		return {
			menu: [],
			collapse: {},
		};
	},
	computed: {
		...mapGetters(['authenticated_user', 'can']),
		cp_state_toggle: function () {
			return this.$store.getters['state_toggler'];
		},
	},
	created() {
		this.mtd_getMenu();
		this.buildCollapseList();
	},
	methods: {
		...mapActions(['execute_commit']),
		mtd_sidebar: function () {
			this.execute_commit({ mutation: 'AUTH__STATE_TOGGLE', payload: {} });
		},
		isRouteActive(paths) {
			paths = Array.isArray(paths) ? paths : [paths];
			return paths.indexOf(this.$route.path.replace('/', '/')) > -1;
		},
		buildCollapseList() {
			let collapse_base = {};
			this.menu
				.filter(({ heading }) => !heading)
				.forEach(({ name, path, submenu }) => {
					collapse_base[name] = this.isRouteActive(submenu ? submenu.map(({ path }) => path) : path);
				});
			this.collapse = collapse_base;
		},
		mtd_showSection(permissions) {
			let rpta = false;
			permissions.forEach(permission => {
				if (this.can.includes(permission)) rpta = true;
			});
			return rpta;
		},
		routeActiveClass(paths) {
			return { active: this.isRouteActive(paths) };
		},
		getSubRoutes(item) {
			return item.submenu.map(({ path }) => path);
		},
		toggleItemCollapse: function (collapseName) {
			let actual = this.collapse[collapseName];
			for (let c in this.collapse) {
				this.collapse[c] = false;
			}
			this.collapse[collapseName] = !actual;
		},
		mtd_getMenu() {
			const rol = this.authenticated_user.roles[0];
			this.menu = rol_menu[rol.code];
		},
	},
};
</script>
