const menu = [
	{
		heading: 'MENU',
	},
	{
		name: 'Escritorio',
		path: '/home',
		icon: 'ri-home-8-line',
		permission: 'home-index',
	},
	{
		name: 'Postulantes',
		path: '/doctor/postulants/index',
		icon: 'fas fa-users',
		permission: 'home-index',
	},
	/** CAMBIAR CONTRASEÑA */
	{
		name: 'Cambiar contraseña',
		path: '/change-password',
		icon: 'ri-lock-line',
		permission: 'home-index',
	},
];

export default menu;
